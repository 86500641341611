const generateStats = (count, detected, ratio) => {
  return [

    {
      "title": "All Campaigns",
      "total": count?.length || 0,
      "info": [
        {
          "icon": '',
          "text": '.'
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Active Campaigns",
      "total": count?.filter((x)=>x.status ==="Active").length || 0,
      "info": [
        {
          "icon": ratio?.active_ratio > 1 ? "fa fa-chevron-up fa-fw me-1":ratio?.active_ratio && "fa fa-chevron-down fa-fw me-1",
          "text": ratio?.active_ratio > 1 ? `${ratio?.active_ratio.toFixed()}% more than last Year`: ratio?.active_ratio ? `${Math.abs(ratio?.active_ratio.toFixed(2))}% Less than last Year`:"."
        }
      ],
      "chartData": [
        {
          "name": "Visitors",
          "data": [
            69,
            34,
            70,
            73,
            41,
            32,
            73,
            72,
            89,
            57,
            42,
            86,
            78
          ]
        }
      ],
      "chartType": "bar",
      "chartHeight": 30,
      
    },
    {
      "title": "Reported Links",
      "total": `${detected?.reported_links ? detected?.reported_links : 0}`,
      "info": [
        {
          "icon": ratio?.reported_ratio > 1 ? "fa fa-chevron-up fa-fw me-1": ratio?.reported_ratio && "fa fa-chevron-down fa-fw me-1",
          "text": ratio?.reported_ratio > 1 ? `${ratio?.reported_ratio.toFixed()}% more than last Year`:ratio?.reported_ratio?`${Math.abs(ratio?.reported_ratio.toFixed(2))}% Less than last Year`:'.'
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Removed Links",
      "total": `${detected?.removed_links ? detected?.removed_links : 0}`,
      "info": [
        {
          "icon": ratio?.removed_ratio > 1 ? "fa fa-chevron-up fa-fw me-1": ratio?.removed_ratio && "fa fa-chevron-down fa-fw me-1",
          "text": ratio?.removed_ratio > 1 ? `${ratio?.removed_ratio.toFixed()}% more than last Year`:ratio?.removed_ratio?`${Math.abs(ratio?.removed_ratio.toFixed(2))}% Less than last Year`:'.'
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Removal Success Rate",
      "total": `${detected?.removal_rate ? detected?.removal_rate + "%" : 0} `,
      "info": [
        {
          "icon": ratio?.success_ratio > 1 ? "fa fa-chevron-up fa-fw me-1": ratio?.success_ratio && "fa fa-chevron-down fa-fw me-1",
          "text": ratio?.success_ratio > 1 ? `${ratio?.success_ratio.toFixed()}% more than last Year`:ratio?.success_ratio?`${Math.abs(ratio?.success_ratio?.toFixed(2))}% Less than last Year`:'.'
        }
        
      ],
      "chartData": [
        77,
        80,
        70
      ],
      "chartType": "pie",
      "chartHeight": 45
    }
  ]
}

export default generateStats