import React from 'react'
import { Link } from 'react-router-dom'

const CampaignCard = ({ item, type }) => {

    if (type === "top") {


        return (
            <div className='max-w-[205px] h-96 border rounded-2xl relative overflow-hidden'>
                {/* <div className='absolute top-2 left-2'>
                    <img src={item?.customer_logo_path} alt='' className='w-10 h-10 rounded-full' />
                </div> */}
                <img src={item?.campaign_poster} alt='' className='object-cover w-full h-[80%]' />
                <div className='h-[20%] w-full  p-2 '>
                    <div className='flex flex-col items-center'>

                        <h6>
                            {item?.campaign_name}
                        </h6>
                        <p>Reported Links : {item.reported}</p>
                    </div>
                </div>
            </div>
        )
    }
    if (type === "active") {
        return (
            <Link to={`/campaigns-analytics/general/${item.id}`}>
                <div className='max-w-[205px] h-96 border rounded-2xl relative overflow-hidden'>
                    {/* <div className='absolute top-2 left-2'>
                        <img src={item?.customers_logos?.[0]} alt='' className='w-10 h-10 rounded-full' />
                    </div> */}
                    <img src={item?.campaign_poster_photo_id?.name} alt='' className='object-cover w-full h-[80%]' />
                    <div className='h-[20%] w-full  p-2 flex flex-col gap-2'>
                        <h6 className=' m-0'>
                            {item?.campaign_name}
                        </h6>
                        <div>
                            {item?.status === "Active" ? <span className="badge bg-success">Active</span> : <span className="badge bg-warning">InActive</span>}
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default CampaignCard