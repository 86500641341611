import React, { useEffect, useState } from 'react'
import Loader from '../../pages/pages/loader';
import { useDispatch, useSelector } from 'react-redux';
import { GetTicket } from '../../redux/apis/ActionApi';
import ReusableTable from '../other/ReuseableTable';

const RequestsQuery = ({id}) => {
    const dispatch = useDispatch()
    const {tickets, error, isLoading}=useSelector(state=>state.action)
    const [holder, setHolder] = useState()
    const [show, setShow] = useState()

    useEffect(()=>{
        if(!id)return
        dispatch(GetTicket({id:id}))
    },[id])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Adjust formatting as needed
      };

    const columns = [
        { title: "ID", dataIndex: "id", key: "id", sorter: true },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            sorter: true,
            search: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            search: true,
        },

        {
            title: 'Ticket Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            search: true,
            render: (text, record) => (
               <p>{formatDate(text)}</p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            search: true,
        },
        
        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     render: (text, record) => (
        //         <div className='flex gap-2'>
        //             <button className='btn btn-outline-theme' onClick={() => handleShow(record)}>More Info</button>
        //         </div>
        //     ),
        // },
        
    ];
    const handleShow = (record) => {
        setHolder(record)
        setShow(true)
    };
    return (
        <div className='w-full flex flex-col'>
            <Loader isLoading={isLoading} />
            <br />
            {tickets ?
                <ReusableTable
                    data={tickets}
                    columns={columns}
                />
                : null
            }
        </div>
    )
}

export default RequestsQuery