import { createSlice } from '@reduxjs/toolkit'
import { getAnalyticsSummary, getCampaignDetailsQuery, getCustomerInfo, getDailyMonthlyRemovalsQuery, getDetectedRemovedInsightsQuery, getNumberOfCampaigns, getPerCampaignStatisticQuery, getPerServiceStatisticQuery, getRatioStatsQuery, getTopCampaignsQuery, getTopPiratedDomainsQuery, getTopRemovalsPerServiceQuery } from '../apis/analyticsApiGeneral';


const initialState = {
  campaignsCount: null,
  campaignDetails:null,
  customerInfo:null,
  detectedRemovedInsightsQuery:null,
  analyticsSummary:null,
  perServiceStatisticQuery:null,
  perCampaignStatisticQuery:null,
  topRemovalsPerServiceQuery:null,
  dailyMonthlyRemovalsQuery:null,
  topPiratedDomainsQuery:null,
  topCampaignsQuery:null,
  ratio:null,
  isLoading: false,
  error: null,
  success: false
}

export const analyticsGeneralSlice = createSlice({
  name: 'analyticsGeneral',
  initialState,
  reducers: {
    clearAnalyticsSummary:(state)=>{
      state.analyticsSummary = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNumberOfCampaigns.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNumberOfCampaigns.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsCount = action.payload
      })
      .addCase(getNumberOfCampaigns.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getCustomerInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCustomerInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInfo = action.payload
      })
      .addCase(getCustomerInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getDetectedRemovedInsightsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDetectedRemovedInsightsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detectedRemovedInsightsQuery = action.payload
      })
      .addCase(getDetectedRemovedInsightsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getAnalyticsSummary.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAnalyticsSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.analyticsSummary = action.payload
      })
      .addCase(getAnalyticsSummary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getPerServiceStatisticQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPerServiceStatisticQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.perServiceStatisticQuery = action.payload
      })
      .addCase(getPerServiceStatisticQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getPerCampaignStatisticQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPerCampaignStatisticQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.perCampaignStatisticQuery = action.payload
      })
      .addCase(getPerCampaignStatisticQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getTopRemovalsPerServiceQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopRemovalsPerServiceQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topRemovalsPerServiceQuery = action.payload
      })
      .addCase(getTopRemovalsPerServiceQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getDailyMonthlyRemovalsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDailyMonthlyRemovalsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dailyMonthlyRemovalsQuery = action.payload
      })
      .addCase(getDailyMonthlyRemovalsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getTopPiratedDomainsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopPiratedDomainsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topPiratedDomainsQuery = action.payload
      })
      .addCase(getTopPiratedDomainsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getTopCampaignsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTopCampaignsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topCampaignsQuery = action.payload
      })
      .addCase(getTopCampaignsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getCampaignDetailsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCampaignDetailsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignDetails = action.payload
      })
      .addCase(getCampaignDetailsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      
      .addCase(getRatioStatsQuery.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getRatioStatsQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ratio = action.payload
      })
      .addCase(getRatioStatsQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      
  },
})

// Action creators are generated for each case reducer function
export const {clearAnalyticsSummary } = analyticsGeneralSlice.actions

export default analyticsGeneralSlice.reducer