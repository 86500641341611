const generateStatsSocial = (count, detected) => {
  return [
    {
      "title": "Active Campaigns",
      "total": count?.active_campaigns || 0,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "33.3% more than last week"
        }
      ],
      "chartData": [
        {
          "name": "Visitors",
          "data": [
            69,
            34,
            70,
            73,
            41,
            32,
            73,
            72,
            89,
            57,
            42,
            86,
            78
          ]
        }
      ],
      "chartType": "bar",
      "chartHeight": 30,
      
    },
    {
      "title": "All Campaigns",
      "total": count?.all_campaigns || 0,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "20.4% more than last week"
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Reported Links",
      "total": `${detected?.reported || 0}`,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "20.4% more than last week"
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Removed Links",
      "total": `${detected?.removed || 0 }`,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "20.4% more than last week"
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81,
            89,
            71,
            75,
            53,
            47,
            78,
            33,
            71,
            81,
            45,
            43,
            68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "Removal Success Rate",
      "total": `${ detected?.removal_rate || 0 + "%" } `,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": `${detected?.removal_rate || 0 } % Removal rate`
        },
        
      ],
      "chartData": [
        77,
        80,
        70
      ],
      "chartType": "pie",
      "chartHeight": 45
    }
  ]
}

export default generateStatsSocial