import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dailyDetectedReportedRemoved } from '../../../redux/apis/analyticsApiSocial'
import { Card, CardBody, CardExpandToggler } from '../../other/card/card'
import ComponentLoader from '../../other/ComponentLoader'
import ChartApex from '../../other/charts/ChartApex'

const DailyReportedRemoved = ({campaign_id}) => {
    const dispatch = useDispatch()
    const { isLoading, dailyDetectedReportedRemovedCampaigns } = useSelector(state => state.analyticsSocial)
    const [numOfDays, setNumOfDays] = React.useState(7);

    useEffect(() => {
        dispatch((dailyDetectedReportedRemoved(
            {
                num_of_days: numOfDays,
                campaign_id: campaign_id
            }
        )))
    }, [numOfDays])

    return (
        <div className="col-xl-8 col-lg-12 ">
            <Card className="mb-3">
                <CardBody className="relative p-0">
                    {isLoading?.dailyDetectedReportedRemoved ? <ComponentLoader /> : null}
                    <div className='p-4'>

                        <div className="d-flex fw-bold small mb-3">
                            <span className="flex-grow-1">-</span>
                            <CardExpandToggler />
                        </div>
                        <Card>
                            <CardBody>
                                <div className="mb-4">
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>Daily Reported / Removed</div>
                                        <select
                                              style={{
                                                width: "30%",
                                                backgroundColor: "transparent"
                                            }}
                                            type="select"
                                            className="mt-2"
                                            value={numOfDays}
                                            onChange={(e) => setNumOfDays(e.target.value)}>
                                            <option value="7">Last 7 days</option>
                                            <option value="14">Last 14 days</option>
                                            <option value="30">Last 30 days</option>
                                        </select    >
                                    </div>

                                </div>
                                {dailyDetectedReportedRemovedCampaigns ?
                                    // <MonthlyDetectedRemovedGraph data={data} dataColors='["--bs-danger","--bs-success"]' numOfDays={numOfDays} setNumOfDays={setNumOfDays} />
                                    <ChartApex data={dailyDetectedReportedRemovedCampaigns} numOfDays={numOfDays} />
                                    : <></>}
                            </CardBody>
                        </Card>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default DailyReportedRemoved