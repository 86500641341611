import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardExpandToggler } from '../../other/card/card'
import ComponentLoader from '../../other/ComponentLoader'
import google_logo from '../../../assets/services/google_logo.png'
import bing_logo from '../../../assets/services/bing_logo.png'
import cyberlocker_logo from '../../../assets/services/cyberlocker_logo.png'
import okru_logo from '../../../assets/services/okru_logo.png'
import dailymotion_logo from '../../../assets/services/dailymotion_logo.png'
import vimeo_logo from '../../../assets/services/vimeo_logo.png'
import telegram_logo from '../../../assets/services/telegram_logo.png'
import app_store_logo from '../../../assets/services/app-stores.webp'

import { getPerServiceStatisticQuery } from '../../../redux/apis/analyticsApiGeneral'

const serviceLogos = {
  google: google_logo,
  bing: bing_logo,
  cyberlocker: cyberlocker_logo,
  okru: okru_logo,
  dailymotion: dailymotion_logo,
  vimeo: vimeo_logo,
  telegram: telegram_logo,
  apps: app_store_logo
  // tiktok: tiktokLogo,
  // youtube: youtubeLogo,
  // facebook: facebookLogo,
  // instagram: instagramLogo,
  // twitter: twitterLogo,
}

const ServiceCard = ({ title, reported_links, removal_rate, photo }) => (
  <Card>
    <CardBody
      className='p-2 pt-4'
      style={{ minHeight: '210px', width: '100%', marginRight: '5px' }}
    >
      <div className='flex flex-col items-center mb-3'>
        <img src={photo} alt={title} className='w-8 h-8' />
        <h5 className='mt-4 mb-2 font-size-15'>{title.toUpperCase()}</h5>
        <p className='mb-0'>
          <b>{reported_links}</b> Reported Links
        </p>
        <p className='mb-0'>
          <b>
            {removal_rate % 1 === 0 ? Math.round(removal_rate) : removal_rate}%
          </b>{' '}
          REMOVAL SUCCESS RATE
        </p>
      </div>
    </CardBody>
  </Card>
)

const ServicesDataGeneral = ({ id }) => {
  const [currentYear, setCurrentYear] = useState(true)
  const dispatch = useDispatch()
  const [servicesInfo, setServicesInfo] = useState([])
  const { isLoading, perServiceStatisticQuery } = useSelector(
    state => state.analyticsGeneral
  )
  useEffect(() => {
    if (!id) return
    dispatch(getPerServiceStatisticQuery({ id: id }))
  }, [id])
  useEffect(() => {
    if (!perServiceStatisticQuery) return

    const updatedServicesInfo = Object.keys(serviceLogos).map(service => ({
      title: service,
      reported_links: currentYear? perServiceStatisticQuery[service]?.reported : perServiceStatisticQuery[service]?.last_year_reported || 0,
      removal_rate: currentYear ? perServiceStatisticQuery[service]?.removal_rate: perServiceStatisticQuery[service]?.last_year_removal_rate || 0,
      photo: serviceLogos[service]
    }))

    setServicesInfo(updatedServicesInfo)
  }, [perServiceStatisticQuery, currentYear])

  const loadingCards = Object.entries(serviceLogos).map(([key, value]) => (
    <ServiceCard
      key={key}
      title={key}
      reported={0}
      removal_rate={0}
      photo={value}
    />
  ))
  return (
    <div className='col-xl-12 col-lg-6'>
      <Card className='mb-3'>
        <CardBody className='relative p-0'>
          {isLoading ? <ComponentLoader /> : null}
          <div className='p-4'>
            <div className='d-flex fw-bold small mb-3'>
              <span className='flex-grow-1'>Platforms Insights</span>
              <CardExpandToggler />
            </div>
            <div className='mb-3 flex gap-3'>
              <button
                className={`btn ${
                    currentYear
                    ? 'btn-outline-theme'
                    : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(true)}
              >
                Current Year
              </button>
              <button
                className={`btn ${
                    !currentYear 
                    ? 'btn-outline-theme'
                    : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(false)}
              >
                previous year
              </button>
            </div>
            <div className='flex flex-wrap justify-center gap-8'>
              {perServiceStatisticQuery
                ? servicesInfo.map(item => (
                    <ServiceCard key={item.title} {...item} />
                  ))
                : loadingCards}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ServicesDataGeneral
