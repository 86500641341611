import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardExpandToggler } from '../../other/card/card';
import ComponentLoader from '../../other/ComponentLoader';
import { dailyDetectedReportedRemoved, detectedReportedRemoved } from '../../../redux/apis/analyticsApiSocial';
import generateStatsSocial from '../../Dashboard/data/statsSocial';

const OverallData = ({campaign_id}) => {
    const dispatch = useDispatch()

    const { isLoading, campaignsCount, detectedreportedRemovedCampaigns } = useSelector(state => state.analyticsSocial)

    const [statsData, setStatsData] = useState();
    const [chartOptions, setChartOptions] = useState(getChartOptions());


    useEffect(() => {
            dispatch(detectedReportedRemoved({ campaign_id: campaign_id }))
        

    }, [ campaign_id])







    function getChartOptions() {
        var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
        var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();

        return {
            bar: {
                colors: [themeColor],
                chart: { sparkline: { enabled: true } },
                tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } }
            },
            line: {
                colors: [themeColor],
                chart: { sparkline: { enabled: true } },
                stroke: { curve: 'straight', width: 2 },
                tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } }
            },
            pie: {
                colors: ['rgba(' + themeColorRgb + ', 1)', 'rgba(' + themeColorRgb + ', .75)', 'rgba(' + themeColorRgb + ', .5)'],
                chart: { sparkline: { enabled: true } },
                stroke: { show: false },
                tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } }
            },
            donut: {
                colors: ['rgba(' + themeColorRgb + ', .15)', 'rgba(' + themeColorRgb + ', .35)', 'rgba(' + themeColorRgb + ', .55)', 'rgba(' + themeColorRgb + ', .75)', 'rgba(' + themeColorRgb + ', .95)'],
                chart: { sparkline: { enabled: true } },
                stroke: { show: false },
                tooltip: { x: { show: false }, y: { title: { formatter: function (seriesName) { return '' } } } },
            }
        };
    }

    useEffect(() => {
        setStatsData(generateStatsSocial(campaignsCount, detectedreportedRemovedCampaigns))
        document.addEventListener('theme-reload', () => {
            setChartOptions(getChartOptions());
        });

        // eslint-disable-next-line
    }, [campaignsCount, detectedreportedRemovedCampaigns]);
    return (
        <div className='flex justify-between gap-2'>
            {statsData && statsData.length > 0 && statsData.slice(2).map((stat, index) => (
                <div className="flex-grow" key={index}>
                    <Card className="mb-3">
                        <CardBody className="relative p-0">
                            {(isLoading?.numberOfCampaigns || isLoading?.detectedReportedRemoved) ? <ComponentLoader /> : null}
                            <div className='p-4'>
                                <div className="d-flex fw-bold small mb-3">
                                    <span className="flex-grow-1">{stat?.title}</span>
                                    <CardExpandToggler />
                                </div>
                                <div className="row align-items-center mb-2">
                                    <div className="col-7">
                                        <h4 className="mb-0">{stat?.total}</h4>
                                    </div>
                                    <div className="col-3">
                                        <div className="mt-n3">
                                            <Chart type={stat?.chartType} height={stat?.chartHeight} options={chartOptions[stat?.chartType]} series={stat?.chartData} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="small text-inverse text-opacity-50 text-truncate">
                                    {stat.info?.length > 0 && stat.info.map((info, index) => (
                                        <div key={index}><i className={info?.icon}></i> {info?.text}</div>
                                    ))}
                                </div> */}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            ))}
        </div>
    )
}

export default OverallData