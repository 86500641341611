import React, { useEffect } from 'react'
import { Card, CardBody, CardExpandToggler } from '../../other/card/card'
import ComponentLoader from '../../other/ComponentLoader'
import { useDispatch, useSelector } from 'react-redux'
import { getTopPiratedDomainsQuery } from '../../../redux/apis/analyticsApiGeneral'
import BarChart from '../../other/charts/BarChart'

const TopPiratedGeneral = ({ id }) => {
    const dispatch = useDispatch()
    const { isLoading, topPiratedDomainsQuery } = useSelector(state => state.analyticsGeneral)
   
    useEffect(() => {
        if (!id) { return }
        dispatch(getTopPiratedDomainsQuery({ id }))
    }, [id])
    return (
        
            <div className="">
                <Card className="mb-3 mt-8">
                    <CardBody className="relative p-0">
                        {isLoading ? <ComponentLoader /> : null}
                        <div className='p-4'>
                            <div className="d-flex fw-bold small mb-3">
                                <span className="flex-grow-1">Top Pirated Domains For The Current Year</span>
                                <CardExpandToggler />
                            </div>
                            <div className="w-full">

                                {topPiratedDomainsQuery ? <BarChart data={topPiratedDomainsQuery} /> : null}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        
    )
}

export default TopPiratedGeneral