import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardExpandToggler } from '../../other/card/card'
import CampaignCardSocial from '../../other/card/campaignCardSocial'
import ComponentLoader from '../../other/ComponentLoader'
import { topCampaignsDetectedReportedRemoved } from '../../../redux/apis/analyticsApiSocial'

const TopCampaignsSocial = () => {
  const dispatch = useDispatch()
  const { isLoading, topDetectedReportedRemovedCampaigns } = useSelector(
    state => state.analyticsSocial
  )
  const [currentYear, setCurrentYear] = useState(true)

  useEffect(() => {
    if (!topDetectedReportedRemovedCampaigns) {
      dispatch(topCampaignsDetectedReportedRemoved())
    }
  }, [])

  return (
    <div className='col-xl-12 col-lg-6 '>
      <Card className='mb-3 min-h-[382px]'>
        <CardBody className='relative p-0'>
          {isLoading.topCampaignsDetectedReportedRemoved ? (
            <ComponentLoader />
          ) : null}
          <div className='p-4'>
            <div className='d-flex fw-bold small mb-3'>
              <span className='flex-grow-1'>Top Campaings</span>
              <CardExpandToggler />
            </div>
            <div className='mb-3 flex gap-3'>
              <button
                className={`btn ${
                  currentYear ? 'btn-outline-theme' : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(true)}
              >
                Current Year
              </button>
              <button
                className={`btn ${
                  !currentYear ? 'btn-outline-theme' : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(false)}
              >
                previous year
              </button>
            </div>
            <div className='flex gap-4 flex-wrap justify-center'>
              {topDetectedReportedRemovedCampaigns &&
              topDetectedReportedRemovedCampaigns.filter(item =>
                currentYear ? !item.is_last_year : item.is_last_year
              ).length > 0 ? (
                topDetectedReportedRemovedCampaigns
                  .filter(item =>
                    currentYear ? !item.is_last_year : item.is_last_year
                  )
                  .map(item => (
                    <CampaignCardSocial
                      type={'top'}
                      item={item}
                      key={item.campaign_name}
                    />
                  ))
              ) : (
                <p className='text-2xl'>No Data Found</p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default TopCampaignsSocial
