// menuConfig.js

const getMenuItems = (haveSocial) => {
    return [
        { is_header: true, title: 'MENU' },
        {
            path: '/dashboard', icon: 'fas fa-bars', title: 'Dashboard',
            children: [
                { path: '/dashboard/general', title: 'General Dashboard' },
                haveSocial && { path: '/dashboard/social-media', title: 'Social Media Dashboard' }
            ].filter(Boolean)
        },
        {
            path: '/campaigns-analytics', icon: 'fas fa-chart-pie', title: 'Campaigns',
            children: [
                { path: '/campaigns-analytics/general', title: 'General Analytics' },
                haveSocial && { path: '/campaigns-analytics/social-media', title: 'Social Media Analytics' },
            ].filter(Boolean)
        },
        { path: '/submit-request', icon: 'fas fa-edit', title: 'Submit a request' },
        {
            path: '/generate-reports', icon: 'far fa-file-archive', title: 'Generate Reports',
            children: [
                {path:'/generate-reports/general', title: 'General Reports' },
                haveSocial && {path:'/generate-reports/social-media', title: 'Social Media Reports' },
            ].filter(Boolean)
        },
        { path: 'https://airtable.com/login', icon: 'fas fa-table', title: 'Air table' },
    ].filter(Boolean);
};

export default getMenuItems;
