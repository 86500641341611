import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import instanceAdmin from '../helpers/configAdmin';
import Loader from '../pages/pages/loader';

function CampaignDropDown({ onChange, onBlur, value, validation, customer_id = null, optional = false }) {

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                setIsLoading(true);
                const response = await instanceAdmin.get(`/campaigns_management/campaigns?page=*&fields=id,campaign_name${customer_id ? "&customer_id=" + customer_id : ""}`);
                const options = response.results?.map((item) => ({
                    label: item.campaign_name,
                    value: item.id,
                })) || [];
                
                if (optional) {
                    options.unshift({ label: "All", value: null });
                }
                setCampaignOptions(options);
            } catch (err) {
                setError(err.message || 'Failed to fetch campaigns');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCampaigns();
    }, [customer_id, optional]);

   

    return (
        <div className='mb-3'>
            <label htmlFor="campaign_name">Campaign</label>
            <br/>
            <Select
                cacheOptions
                defaultOptions
                onChange={onChange}
                className={"customSelect w-full"}
                classNamePrefix="select"
                onBlur={onBlur}
                value={value}
                isLoading={isLoading}
                aria-invalid={validation.touched.campaign_id && validation.errors.campaign_id ? true : false}
                options={campaignOptions}
            />
            {validation.touched.campaign_id && validation.errors.campaign_id ? (
                <div className="text-danger">{validation.errors.campaign_id}</div>
            ) : null}
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
}

export default CampaignDropDown;
