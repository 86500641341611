import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '../../helpers/auth';
import { getCustomerInfo } from '../../redux/apis/analyticsApiGeneral';
import AnalyticsBoxGeneral from '../../components/Dashboard/general/AnalyticsBoxGeneral';
import PerServiceStatsGeneral from '../../components/Dashboard/general/PerServiceStatsGeneral';
import TopCampaignsGeneral from '../../components/Dashboard/general/TopCampaignsGeneral';
import ServicesDataGeneral from '../../components/Dashboard/general/ServicesDataGeneral';
import MonthlyDetectedRemovedGeneral from '../../components/Dashboard/general/MonthlyDetectedRemovedGeneral';
import TopRemovalsGeneral from '../../components/Dashboard/general/TopRemovalsGeneral';
import TopPiratedGeneral from '../../components/Dashboard/general/TopPiratedGeneral';

const Dashboard = React.memo(() => {
    const context = useContext(AppSettings);
    const dispatch = useDispatch()
    const { customerInfo } = useSelector(state => state.analyticsGeneral)
    const userId = getUserId()
    const [customerId, setCustomerId] = useState(null)
    useEffect(() => {
        if (!userId) { return }
        if (!customerInfo) {
            dispatch(getCustomerInfo({ id: userId }))
        }
    }, [userId])

    useEffect(() => {
        if(!customerInfo?.id)return
        localStorage.setItem("customerId", customerInfo?.id);
        localStorage.setItem("image",customerInfo?.media?.name)
        setCustomerId(customerInfo?.id)
    }, [customerInfo])

    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return () => {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]); 

    return (
        <div>
            <div className="row">
                <AnalyticsBoxGeneral id={customerId}/>
                <PerServiceStatsGeneral id={customerId}/>
                <TopCampaignsGeneral id={customerId}/>
                <ServicesDataGeneral id={customerId}/>
                <div className='flex justify-between wrapContainer gap-4' style={{flexWrap:"wrap"}}>
                    <MonthlyDetectedRemovedGeneral id={customerId} />
                    <TopRemovalsGeneral id={customerId}/>
                </div>
                <TopPiratedGeneral id={customerId}/>
            </div>
        </div>
    );
});

export default Dashboard;
