import { Col, Row } from 'antd';
import React, { useEffect } from 'react'
import { Card, CardBody } from '../../other/card/card';
import { useDispatch, useSelector } from 'react-redux';
import { topRemovals } from '../../../redux/apis/analyticsApiSocial';
import ComponentLoader from '../../other/ComponentLoader';
import { getDailyMonthlyRemovalsQuery, getTopRemovalsPerServiceQuery } from '../../../redux/apis/analyticsApiGeneral';

const TopRemovalsCampaignGeneral = ({ campaign_id, id }) => {
    const dispatch = useDispatch()
    const { isLoading, dailyMonthlyRemovalsQuery, topRemovalsPerServiceQuery } = useSelector(state => state.analyticsGeneral)

    useEffect(() => {
        if (!id) { return }
        dispatch(getDailyMonthlyRemovalsQuery({
            id: id,
            params: {
                campaign_id: campaign_id
            }
        }))

        dispatch(getTopRemovalsPerServiceQuery({
            id: id,
            params: {
                campaign_id: campaign_id
            }
        }))
    }, [id])

    const serviceColorLookup = {
        google: "#3aa757",
        dailymotion: "#34406b",
        okru: "#ffffff",
        cyberlocker: "#ff6600",
        bing: "#ffcc00",
        google: "#33cc33",
        telegram: "#1a1aff",
        vimeo: "#853ec8",
        facebook: '#2a569d',
        instagram: '#C13584',
        twitter: '#1DA1F2',
        youtube: '#FF0000',
        tiktok: '#000000',
      };
    return (
        <div className="flex-1">
            <Card >
                <CardBody className={"relative p-0"}>

                    {isLoading ? <ComponentLoader /> : null}
                    <div className='p-4'>

                        <div className="d-flex flex-wrap">
                            <div className="ms-2">
                                <h5 className="card-title mb-3">Top Removals For The Current Year</h5>
                            </div>
                        </div>
                        <Row className="text-center">
                            <Col xs={6}>
                                <div className="mt-3">
                                    <p className="mb-1">Today</p>
                                    <h5>{dailyMonthlyRemovalsQuery?.today_removed !== null && dailyMonthlyRemovalsQuery?.today_removed !== undefined ? dailyMonthlyRemovalsQuery?.today_removed : null}</h5>
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div className="mt-3">
                                    <p className="mb-1">This Month</p>
                                    <h5>
                                        {dailyMonthlyRemovalsQuery?.this_month_removed !== null && dailyMonthlyRemovalsQuery?.this_month_removed !== undefined ? dailyMonthlyRemovalsQuery?.this_month_removed : null}
                                    </h5>
                                </div>
                            </Col>
                        </Row>

                        <hr />

                        <div>
                            <ul className="list-group list-group-flush">
                                {topRemovalsPerServiceQuery ?
                                    Object.entries(topRemovalsPerServiceQuery).map(([service, data]) => (
                                        <li className="list-group-item" key={service}>
                                            <div className="py-2">
                                                <h5 className="font-size-14">
                                                    {service} <span className="float-end">{data.removed}%</span>
                                                </h5>
                                                <div className="progress animated-progess progress-sm">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: `${data.removed}%`, backgroundColor: serviceColorLookup[service] || '#66BC0F' }}
                                                        aria-valuenow={data.removed}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </li>
                                    )) : Array(3).fill(0).map((_, i) => (
                                        <li className="list-group-item" key={i}>
                                            <div className="py-2">
                                                <div className="progress animated-progess progress-sm">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: `0%`, backgroundColor: '#66BC0F' }}
                                                        aria-valuenow={50}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}

                            </ul>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default TopRemovalsCampaignGeneral